import { Button } from "react-bootstrap";

export default function Uav () {
  return (
    <div className="uav">
      <Button href="/JP-R30">JP-R30</Button>
      <Button href="/JP-REOD400 ">JP-REOD400 </Button>
    </div>
  )
}

import "./feedback.css";

function BasicExample() {
  return (
    <div className="feedback">
      <p>You can contact us.</p>
      <p>Address: House of Francis, Room 303, Ile Du Port, Mahe, Seychelle</p>
      <p>Phone: +372 81941296</p>
    </div>
  );
}

export default BasicExample;


import Header from "./components/header"
import Text from "./components/text"

export default function Projects () {
    return (
        <div>
            <Header />
            {/* <Cardlist /> */}
            <Text />
        </div>
    )
}